import React, { lazy } from 'react'

const Home = lazy(() =>
  import('./Home').then(module => ({
    default: module.Home,
  }))
)

const PublicHolidayCalendar = lazy(() =>
  import('./PublicHolidayCalendar/PublicHolidayCalendar').then(module => ({
    default: module.PublicHolidayCalendar,
  }))
)

const PublicHolidayCalendarForm = lazy(() =>
  import('./PublicHolidayCalendar/PublicHolidayCalendarForm').then(module => ({
    default: module.PublicHolidayCalendarForm,
  }))
)

const PublicHolidayUploadValidationListing = lazy(() =>
  import('./PublicHolidayCalendar/UploadValidationListing').then(module => ({
    default: module.PublicHolidayUploadValidationListing,
  }))
)

const TaxRelief = lazy(() =>
  import('./TaxRelief/TaxRelief').then(module => ({
    default: module.TaxRelief,
  }))
)

const EAPartF = lazy(() =>
  import('./EAPartF/EAPartF').then(module => ({
    default: module.EAPartF,
  }))
)

const EPFPolicy = lazy(() =>
  import('./EPFPolicy/EPFPolicy').then(module => ({
    default: module.EPFPolicy,
  }))
)

const SOCSOPolicy = lazy(() =>
  import('./SOCSOPolicy/SOCSOPolicy').then(module => ({
    default: module.SOCSOPolicy,
  }))
)

const EISPolicy = lazy(() =>
  import('./EISPolicy/EISPolicy').then(module => ({
    default: module.EISPolicy,
  }))
)

const MobileVersionListing = lazy(() =>
  import('./MobileVersion/MobileVersionListing').then(module => ({
    default: module.MobileVersionListing,
  }))
)

const HomeRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <Home />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <Home />,
  },

  {
    props: { exact: true, path: '/systemadmin/publicholidaycalendar' },
    component: <PublicHolidayCalendar />,
  },
  {
    props: { exact: true, path: '/systemadmin/publicholidaycalendar/new' },
    component: <PublicHolidayCalendarForm />,
  },
  {
    props: { exact: true, path: '/systemadmin/publicholidaycalendar/edit' },
    component: <PublicHolidayCalendarForm />,
  },
  {
    props: {
      exact: true,
      path: '/systemadmin/publicholidaycalendar/excel-upload/validation',
    },
    component: <PublicHolidayUploadValidationListing />,
  },
  {
    props: { exact: true, path: '/systemadmin/taxrelief' },
    component: <TaxRelief />,
  },
  {
    props: { exact: true, path: '/systemadmin/eapartf' },
    component: <EAPartF />,
  },
  {
    props: { exact: true, path: '/systemadmin/epfpolicy' },
    component: <EPFPolicy />,
  },
  {
    props: { exact: true, path: '/systemadmin/socsopolicy' },
    component: <SOCSOPolicy />,
  },
  {
    props: { exact: true, path: '/systemadmin/eispolicy' },
    component: <EISPolicy />,
  },
  {
    props: { exact: true, path: '/systemadmin/mobileversionlisting' },
    component: <MobileVersionListing />,
  },
]

export default HomeRoutes
