import React,{lazy} from "react"


const SOCSOPolicyForm = lazy(() =>
import('./SOCSOPolicyForm').then(module => ({
  default: module.SOCSOPolicyForm,
}))
)

const SOCSOPolicyFormRoutes = [
    {
        props: { exact: true, path: '/systemadmin/socsopolicy/socsopolicyform' },
        component: <SOCSOPolicyForm />,
    }
]

export default SOCSOPolicyFormRoutes