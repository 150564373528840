import React, { lazy } from 'react'

const EPFPolicyForm = lazy(() =>
  import('./EPFPolicyForm').then(module => ({
    default: module.EPFPolicyForm,
  }))
)

const EPFPolicyFormRoutes = [
  {
    props: { exact: true, path: '/systemadmin/epfpolicy/epfpolicyform' },
    component: <EPFPolicyForm />,
  },
]

export default EPFPolicyFormRoutes
