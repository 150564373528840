import React, { lazy } from 'react'

const EISPolicyForm = lazy(() =>
  import('./EISPolicyForm').then(module => ({
    default: module.EISPolicyForm,
  }))
)

const EISPolicyRoutes = [
    
   
    {
      props: { exact: true, path: '/systemadmin/eispolicy/eispolicyform' },
      component: <EISPolicyForm />,
    },
  ]
  
  export default EISPolicyRoutes
  